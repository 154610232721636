import TippyController from "./tippy_controller";

export default class extends TippyController {
  static targets = ["submenu"];

  get trigger() {
    return super.trigger || 'click'
  }

  get placement() {
    return super.placement || 'bottom-end'
  }

  get tippyOptions() {

    const hide = () => {
      // Prevent multiple calls as the event gets triggered
      // at a high rate
      window.removeEventListener('scroll', hide, true);
      this.hide();
    }

    return {
      ...super.tippyOptions,
      touch: true,
      // Hide popover on scroll to limit risks of issues
      onShow() {
        // Scroll events don't bubble so we need to grab them
        // at the capture phase
        window.addEventListener('scroll', hide, true)
      },
      onHide() {
        window.removeEventListener('scroll', hide, true)
      }
    }
  }

  clickOutside(event) {
    if (event.type == "clickoutside" && !event.detail.event.target.hasAttribute("data-popover-target")) {
      this.instance.hide();
    }
  }
}
